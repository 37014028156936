/* 
  Code generated with generateTheme.ts file. DO NOT EDIT. 
  last updated at 2024-11-27T11:55:09.267Z */
:root {
  --branding-blue: #0047EE;
  --branding-purple: #722EF2;
  --branding-pink: #F413F7;
  --interactive-positive: #0891B2;
  --interactive-positive-highlight: #164E63;
  --interactive-destructive: #B91C1C;
  --interactive-destructive-highlight: #7F1D1D;
  --interactive-disabled: #A1A1AA;
  --text-primary: #020617;
  --text-secondary: #64748B;
  --text-disabled: #A1A1AA;
  --text-links: #0891B2;
  --dataviz-1: #722FAE;
  --dataviz-2: #326CC2;
  --dataviz-3: #009BC1;
  --dataviz-4: #008562;
  --dataviz-5: #E9B600;
  --dataviz-6: #D77B51;
  --dataviz-7: #713636;
  --dataviz-8: #EB5FA4;
  --linea-primary: #6366F1;
  --linea-background: #E0E7FF;
  --background-base0: #FFFFFF;
  --background-base1: #FCFCFC;
  --background-base2: #FBFBFB;
  --background-base3: #F8FAFC;
  --background-idle: #F1F5F9;
  --background-active: #E2E8F0;
  --background-destructive-idle: #FEF2F2;
  --background-destructive-active: #FEE2E2;
  --background-shadow: rgba(0, 0, 0, 0.12);
  --outlines-1: #F1F5F9;
  --outlines-2: #E2E8F0;
  --outlines-3: #CBD5E1;
  --outlines-diabled: #0000001F;
  --outlines-focus: #60A5FA;
  --accent-primary: #FDBA74;
  --accent-background: #FFEDD5;
  --severity-critical: #450A0A;
  --severity-high: #B91C1C;
  --severity-medium: #D97706;
  --severity-low: #FCD34D;
  --severity-informational: #93C5FD;
  --semantic-success-primary: #059669;
  --semantic-success-background: #ECFDF5;
  --semantic-error-primary: #B91C1C;
  --semantic-error-background: #FEF2F2;
  --semantic-warning-primary: #FCD34D;
  --semantic-warning-background: #FFFBEB;
  --semantic-informational-primary: #93C5FD;
  --semantic-informational-background: #E2E8F0;
  --backdrops-1: #0000001F;
}

[data-color-scheme="light"] {
  --branding-blue: #0047EE;
  --branding-purple: #722EF2;
  --branding-pink: #F413F7;
  --interactive-positive: #0891B2;
  --interactive-positive-highlight: #164E63;
  --interactive-destructive: #B91C1C;
  --interactive-destructive-highlight: #7F1D1D;
  --interactive-disabled: #A1A1AA;
  --text-primary: #020617;
  --text-secondary: #64748B;
  --text-disabled: #A1A1AA;
  --text-links: #0891B2;
  --dataviz-1: #722FAE;
  --dataviz-2: #326CC2;
  --dataviz-3: #009BC1;
  --dataviz-4: #008562;
  --dataviz-5: #E9B600;
  --dataviz-6: #D77B51;
  --dataviz-7: #713636;
  --dataviz-8: #EB5FA4;
  --linea-primary: #6366F1;
  --linea-background: #E0E7FF;
  --background-base0: #FFFFFF;
  --background-base1: #FCFCFC;
  --background-base2: #FBFBFB;
  --background-base3: #F8FAFC;
  --background-idle: #F1F5F9;
  --background-active: #E2E8F0;
  --background-destructive-idle: #FEF2F2;
  --background-destructive-active: #FEE2E2;
  --background-shadow: rgba(0, 0, 0, 0.12);
  --outlines-1: #F1F5F9;
  --outlines-2: #E2E8F0;
  --outlines-3: #CBD5E1;
  --outlines-diabled: #0000001F;
  --outlines-focus: #60A5FA;
  --accent-primary: #FDBA74;
  --accent-background: #FFEDD5;
  --severity-critical: #450A0A;
  --severity-high: #B91C1C;
  --severity-medium: #D97706;
  --severity-low: #FCD34D;
  --severity-informational: #93C5FD;
  --semantic-success-primary: #059669;
  --semantic-success-background: #ECFDF5;
  --semantic-error-primary: #B91C1C;
  --semantic-error-background: #FEF2F2;
  --semantic-warning-primary: #FCD34D;
  --semantic-warning-background: #FFFBEB;
  --semantic-informational-primary: #93C5FD;
  --semantic-informational-background: #E2E8F0;
  --backdrops-1: #0000001F;
}
[data-color-scheme="dark"] {
  --branding-blue: #0047EE;
  --branding-purple: #722EF2;
  --branding-pink: #F413F7;
  --interactive-positive: #7DD3FC;
  --interactive-positive-highlight: #38BDF8;
  --interactive-destructive: #F87171;
  --interactive-destructive-highlight: #EF4444;
  --interactive-disabled: #475569;
  --text-primary: #CBD5E1;
  --text-secondary: #94A3B8;
  --text-disabled: #64748B;
  --text-links: #7DD3FC;
  --dataviz-1: #A955E6;
  --dataviz-2: #3C83ED;
  --dataviz-3: #88E8FF;
  --dataviz-4: #33CB94;
  --dataviz-5: #FFD74A;
  --dataviz-6: #FFD0A3;
  --dataviz-7: #84503C;
  --dataviz-8: #F27BB6;
  --linea-primary: #6366F1;
  --linea-background: #E0E7FF;
  --background-base0: #000000;
  --background-base1: #0A0A0A;
  --background-base2: #101011;
  --background-base3: #1A1B21;
  --background-idle: #282D38;
  --background-active: #334155;
  --background-destructive-idle: #450A0A;
  --background-destructive-active: #2B0707;
  --background-shadow: ;
  --outlines-1: #27272A;
  --outlines-2: #334155;
  --outlines-3: #64748B;
  --outlines-diabled: #404040;
  --outlines-focus: #3B82F6;
  --accent-primary: #FDBA74;
  --accent-background: #431407;
  --severity-critical: #7F1D1D;
  --severity-high: #F87171;
  --severity-medium: #D97706;
  --severity-low: #FCD34D;
  --severity-informational: #60A5FA;
  --semantic-success-primary: #34D399;
  --semantic-success-background: #022C22;
  --semantic-error-primary: #F87171;
  --semantic-error-background: #2B0707;
  --semantic-warning-primary: #FBBF24;
  --semantic-warning-background: #453603;
  --semantic-informational-primary: #60A5FA;
  --semantic-informational-background: #172554;
  --backdrops-1: #FFFFFF29;
}
/* spacing */ 
 :root {
  --spacing-xxs: 2px; 
  --spacing-xs: 4px; 
  --spacing-s: 8px; 
  --spacing-m: 12px; 
  --spacing-l: 16px; 
  --spacing-xl: 20px; 
  --spacing-2xl: 24px; 
  --spacing-3xl: 32px; 
  --spacing-4xl: 40px; 
}

/* borderRadius */ 
 :root {
  --border-radius-xs: 2px; 
  --border-radius-sm: 4px; 
  --border-radius-md: 8px; 
  --border-radius-lg: 16px; 
}

/* borderWidth */ 
 :root {
  --border-width-large: 4px; 
  --border-width-medium: 2px; 
  --border-width-small: 1px; 
}

/* fontSize */ 
 :root {
  --font-size-s: 12px; 
  --font-size-m: 16px; 
  --font-size-l: 20px; 
  --font-size-xl: 24px; 
  --font-size-xxl: 40px; 
}

/* lineHeight */ 
 :root {
  --line-size-s: 16px; 
  --line-size-m: 20px; 
  --line-size-l: 24px; 
  --line-size-xl: 32px; 
  --line-size-xxl: 56px; 
}

/* outlineWidth */ 
 :root {
  --outline-size-xs: 1px; 
  --outline-size-sm: 2px; 
  --outline-size-md: 3px; 
  --outline-size-lg: 4px; 
  --outline-size-xl: 5px; 
}

/* boxShadow */ 
 :root {
  --box-shadow-shadow1: 1px 1px 4px 0px var(--background-Shadow, rgba(0, 0, 0, 0.12)); 
  --box-shadow-shadow2: 1px 3px 12px 0px var(--background-Shadow, rgba(0, 0, 0, 0.12)); 
  --box-shadow-shadow3: 1px 1px 16px 4px var(--background-Shadow, rgba(0, 0, 0, 0.12)); 
  --box-shadow-shadow4: 0px -1px 4px 0px var(--background-Shadow, rgba(0, 0, 0, 0.12)) inset; 
}

